import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Header from '../components/Header';
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../util/firebaseApp';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const SponsorForm = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [searchParams] = useSearchParams();
    const [formStatus, setFormStatus] = useState('Send');
    const [tcUrl, setTCUrl] = useState('');
    const [bank, setBank] = useState({});
    const [sponsorFor, setSponsorFor] = useState('');
    //const [sponId, setSponId] = useState('');
    //const [sponsorPrices, setSponsorPrices] = useState([]);
    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { name, email, phone, link, acceptTick, image, adlength, hasVideo } = e.target.elements;

        if (!acceptTick.checked) {
            alert("You must accept the terms and conditions to continue.");
            return;
        }

        if (!image.files[0]) {
            alert("An image has not been uploaded");
            return;
        }

        if (!adlength.value) {
            alert("Please select a length of advert.");
            return;
        }

        const sponForm = new FormData();
        sponForm.append("name", name.value);
        sponForm.append("email", email.value);
        sponForm.append("phone", phone.value);
        sponForm.append("url", link.value);
        sponForm.append("image", image.files[0]);
        sponForm.append("sponsor", sponsorFor);
        sponForm.append("videoInclude", hasVideo.checked); // Use .checked for checkbox
        sponForm.append("length", adlength.value);
        sponForm.append("bankId", searchParams.get("bankId"));

        axios
            .post(`${apiUrl}/sponsorrequest/submit-request`, sponForm, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                console.log(response);
                //setSponId(response.data.request_id)
                alert("Submission Successful. Thank you.")
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error)
                }
                console.log(error)
                alert("Submission failed, please try again");
            })
        setFormStatus("Send")
    }

    const fetchTCLink = () => {
        setTCUrl("https://api.fundraiserecycle.com/media/T&C.pdf")
    }

    const loadBankData = async (bankId) => {
        const docRef = doc(db, "banks", bankId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setBank(docSnap.data());
            return docSnap.data();
        }
    }

    useEffect(() => {
        fetchTCLink();
        setSponsorFor(searchParams.get('bankId'));
    }, [])

    useEffect(() => {
        if (sponsorFor !== "") {
            loadBankData(sponsorFor)
        }
    }, [sponsorFor])

    useEffect(() => {
        console.log(formStatus)
    }, [formStatus])

    return (
        <PageContainer>
            <Header />
            <PageContent>
                <Title>Sponsor a Clothes Bank</Title>
                <Subtitle>Sponsorship for {bank.name}</Subtitle>
                <Form onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Name</label>
                        <input className="form-control" type="text" id="name" name="name" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="email">Email Address</label>
                        <input className="form-control" type="text" id="email" name="email" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="phone">Phone Number</label>
                        <input className="form-control" type="text" id="phone" name="phone" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="link">Link for your Advert</label>
                        <input className="form-control" type="text" name="link" id="link" />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="image">Advert Image</label>
                        <input className="form-control" name="image_url" accept="image/jpeg,image/png,image/gif" type="file" id="image" />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="adlength">Advert Length</label>
                        <div id="adlength">
                            <div className="radio-option-container">
                                <input className="form-control radio " type="radio" id="6mths" value="180" name="adlength" />
                                <label className="form-label radio" htmlFor="6mths">6 Months</label> 
                            </div>
                            <div className="radio-option-container">
                                <input className="form-control radio" type="radio" id="12mths" value="360" name="adlength" />
                                <label className="form-label radio" htmlFor="12mths">12 Months</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-field-container checkbox">
                        <label className="form-label" htmlFor="hasVideo">I want to include a video on my advertisement.</label>
                        <input className="form-control" type="checkbox" id="hasVideo" />
                    </div>
                    <div className="form-field-container checkbox">
                        <label className="form-label" htmlFor="acceptTick">I accept the Terms and Conditions laid out <a target="_blank" rel="noreferrer" href={tcUrl}>here</a></label>
                        <input className="form-control" type="checkbox" id="acceptTick" required />
                    </div>
                    <div className="form-field-container checkbox">
                        <label className="form-label" htmlFor="mailTick">I want to join the Fundraise & Recycle Mailing List</label>
                        <input className="form-control" type="checkbox" id="mailTick" defaultChecked />
                    </div>
                    <button className="btn btn-danger" type="submit">
                        {formStatus}
                    </button>
                </Form>
            </PageContent>
        </PageContainer>
    )
}

export default SponsorForm;

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    padding-left: 18px;
`

const Subtitle = styled.h2`
    text-align: center;
    padding-left: 18px;
`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 24px;
    
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
    }
    
    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick,
    #mailTick,
    #hasVideo {
        width: auto;
    }

    .radio-option-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 4px 0;
    }

    .radio-option-container input.radio {
        width: auto;
        margin-right: 10px;
    }

    .radio-option-container label.radio {
        margin: 0;
        color: black;
        font-size: 14px;
    }

    #adlength {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
`