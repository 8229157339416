import react, { useState, useRef } from 'react';
import styled from 'styled-components';
import SharePopup from '../components/SharePopup';
import axios from 'axios';

const CampaignPopup = ({bank, campaign, exitFunction}) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [formStatus, setFormStatus] = useState('Send');
    const [sharePopupActive, setSharePopup] = useState(false);
    const [shareActive, setShareState] = useState(false);
    const form = useRef();

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { name, email, phone } = e.target.elements;
        let campaignForm = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            campaign: campaign.id
        }
        console.log(campaignForm)

        axios
            .post(`${apiUrl}/campaignmember/add-member`, campaignForm)
            .then((response) => {
                console.log(response);
                setShareState(true)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error)
                }
                console.log(error)
                alert("Submission failed, please try again");
            })
    }

    return (
        <Container>
        <PopupContent>
            <CloseButton onClick={() => {exitFunction(false)}}>X</CloseButton>
            <Title>Fundraising Campaign</Title>
            <Subtitle>You've been invited by {campaign ? campaign.name : "CAMPAIGN NOT FOUND"} to join their fundraising campain for {bank ? bank.name : "BANK NOT FOUND"}.</Subtitle>
            <Subtitle>The collection has been booked for {campaign ? campaign.date : "CAMPAIGN NOT FOUND"}. To participate in the fundraising campain for {bank ? bank.name : "BANK NOT FOUND"}, please clear our your wardrobes and drop off what you don't need before the collection date at:<br />{campaign ? campaign.address : "Fundraise & Recycle\n17 Pease Road\nPeterlee\nSR8 2RD"}.</Subtitle>
            {!shareActive ?
                <Form ref={form} onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Name</label>
                        <input className="form-control" name="name" type="text" id="name" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="email">Email Address</label>
                        <input className="form-control" name="email" type="text" id="email" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="phone">Contact Number</label>
                        <input className="form-control" name="phone" type="text" id="phone" required />
                    </div>
                    <button className="btn btn-danger" type="submit">
                        {formStatus}
                    </button>
                </Form> :
                <ShareButton className="cta-3" onClick={() => {
                    if (navigator.share) {
                        navigator.share({
                            title: window.title,
                            text: `I choose you to help me and join my Fundraise and Recycle campaign by recycling and clearing out your wardrobe to help fundraise for ${bank ? bank.name : '.'}.`,
                            url: window.location.href,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    } else {
                        setSharePopup(true);
                    }
                }}>
                    <p>SHARE MY CAMPAIGN!</p>
                </ShareButton>
                }
        </PopupContent>
        </Container>
    )
}

export default CampaignPopup

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .acceptbtn {
        margin-top: 20px;
        background: #ECB525;
    }
`

const PopupContent = styled.div`
    background: white;
    position: relative;
    margin: auto;
    max-width: 580px;
    border-radius: 18px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow: scroll;
`

const CloseButton = styled.a`
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 32px;
    color: black;
    cursor: default;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    margin: 0;
`

const Subtitle = styled.h2`
`

const ShareButton = styled.a`
    background: #BE3258;
    color: white;

    width: 200px;
    text-align: center;
    border-radius: 50px;

    p {
        color: white;
        font-weight: bold;
        padding: 0 20px;
    }

    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`


const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 0 24px 24px 24px;
    
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input,
    textarea,
    select {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        margin-top: 24px;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`
